import { Link } from "gatsby"
import React from "react"
import './footer.css'

const Footer = ({ siteTitle }) => (
    <footer className="footer">
        <hr />
        <div className="center">
            <div className="footer-nav">
                <div className="left-col">
                    <Link to="/">
                        {siteTitle}
                    </Link>
                    <Link to="/about">
                        About Me
                    </Link>
                   <span>© {new Date().getFullYear()}</span>
                    <a href="https://www.linkedin.com/in/lakshmi-deepak-chella-1871821b4"> @CL DEEPAK</a> 
                </div>

                <div className="right-colo">
                
                </div>

            </div>
        </div>
    </footer>
)

Footer.defaultProps = {
    siteTitle: `Behind Java`,
}

export default Footer
